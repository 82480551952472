<template>
  <div>
    <div class="directory">
<!--      <div class="directory-item" v-for="(item,index) in treeList" :key="item.id">-->
<!--        <div class="directory-item-tit" @click="toggle(item)">-->
<!--          <div class="directory-item-tit-l">-->
<!--&lt;!&ndash;            <span>第{{index + 1}}章</span>&ndash;&gt;-->
<!--            <span>{{ item.node.name }}</span>-->
<!--          </div>-->
<!--          <div class="directory-item-tit-r" :style="{transform:item.show ? 'rotate(180deg)' : 'rotate(0deg)'}"></div>-->
<!--          &lt;!&ndash;      <img src="../../../assets/images/attestation/x.png" width="16" height="10" />&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="directory-item-list" :style="{height: item.show ? item.children.length * 70 + 'px' : '0px'}">-->
<!--          <div class="directory-item-list-li" v-for="(sItem,sIndex) in item.children" :key="sItem.id">-->
<!--            <div>-->
<!--              <p class="directory-item-list-li-tit"><span style="padding-right: 10px">{{sIndex > 8 ? sIndex + 1 : '0' + (sIndex + 1)}}</span>{{ sItem.node.name }}</p>-->
<!--              &lt;!&ndash; <p class="directory-item-list-li-subtit" v-if="sItem.data_count > 0">题目练习 {{sItem.have_finished || 0}}/{{ sItem.data_count }}</p> &ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; main_status 2 已完成  0待开始 1学习中 &ndash;&gt;-->
<!--            <div class="directory-item-list-li-btns">-->
<!--              <div class="directory-item-list-li-btn" @click="checkPdf(sItem)" v-if="sItem.ppt_info.length"><img class="icon" src="../../../../assets/images/course/ppt.png" alt="">课件<span>({{ sItem.ppt_info.length }}个)</span></div>-->
<!--              <div class="directory-item-list-li-btn" @click="checkVideo(sItem)" v-if="sItem.vedio_info.length"><img class="icon" src="../../../../assets/images/course/video.png" alt="">视频<span>({{ sItem.vedio_info.length }}个)</span></div>-->
<!--              <div class="directory-item-list-li-btn" @click="checkTest(sItem)" v-if="sItem.enchiridion_info.length"><img class="icon" src="../../../../assets/images/course/exper.png" alt="">实验手册<span>({{ sItem.enchiridion_info.length }}个)</span></div>-->
<!--              <div class="directory-item-list-li-btn" @click="goTrain(sItem)" v-if="sItem.data_count > 0"><img class="icon" src="../../../../assets/images/course/train.png" alt="">试题<span>({{sItem.have_finished || 0}}/{{ sItem.data_count }}题)</span></div>-->
<!--            </div>-->

<!--            &lt;!&ndash;        <div :class="sItem.progress.main_status == 2 ? 'directory-item-list-li-gray-btn' : 'directory-item-list-li-btn'" @click="goTrain(index,sIndex)" >{{sItem.progress.main_status == 2 ? '已完成' : '开始练习'}}</div>&ndash;&gt;-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="directory-item" v-for="(item,index) in treeList" :key="item.id">
        <div  class="directory-item-tit " @click="toggle(item)">
          <div class="directory-item-tit-l">
            <span>{{ item.node.name }}</span>
          </div>
          <div class="directory-item-tit-r" :style="{transform:item.show ? 'rotate(180deg)' : 'rotate(0deg)'}"></div>
        </div>
        <div class="directory-item-list" :style="{maxHeight: item.show ? item.children_total_count * 70 + 'px' : '0px'}">
          <el-tree
              :ref="item.refName"
              :data="item.children"
              node-key="node_child_id"
              label="name"
              default-expand-all
          >
<!--            <span slot-scope="{node,data}" class="category-item">-->
<!--              <span>{{data.node.name}}</span>-->

<!--            </span>-->
            <div slot-scope="{node,data,index}" class="directory-item-list-li">
              <div>
                <p class="directory-item-list-li-tit"><span style="padding-right: 10px">{{data.index > 8 ? data.index + 1 : '0' + (data.index + 1)}}</span>{{ data.node.name }}</p>
              </div>
              <!-- main_status 2 已完成  0待开始 1学习中 -->
              <div class="directory-item-list-li-btns" v-if="!data.children || !data.children.length">
                <div class="directory-item-list-li-btn" @click="checkPdf(data)" v-if="data.ppt_info.length"><img class="icon" src="../../../../assets/images/course/ppt.png" alt="">课件<span>({{ data.ppt_info.length }}个)</span></div>
                <div class="directory-item-list-li-btn" @click="checkVideo(data)" v-if="data.vedio_info.length"><img class="icon" src="../../../../assets/images/course/video.png" alt="">视频<span>({{ data.vedio_info.length }}个)</span></div>
                <div class="directory-item-list-li-btn" @click="checkTest(data)" v-if="data.enchiridion_info.length"><img class="icon" src="../../../../assets/images/course/exper.png" alt="">实验手册<span>({{ data.enchiridion_info.length }}个)</span></div>
                <div class="directory-item-list-li-btn" @click="goTrain(data)" v-if="data.data_count > 0"><img class="icon" src="../../../../assets/images/course/train.png" alt="">试题<span>({{data.have_finished || 0}}/{{ data.data_count }}题)</span></div>
              </div>
            </div>



          </el-tree>
        </div>

      </div>
    </div>

    <el-drawer
      :visible.sync="checkFileModal"
      :destroy-on-close="true"
      size="80%">
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">{{ drawerTitle }}</div>
        </div>
        <div style="padding: 10px 20px; height: calc(100% - 60px);">
          <div class="name-list">
            <div :class="curFileIndex == index ? 'active' : ''" v-for="(item,index) in fileList" :key="item.id" @click="changeFileName(index,item)">{{ item.name }}</div>
          </div>
          <div v-if="fileList.length && fileList[curFileIndex].path" style="height: 100%;">
            <video v-if="fileType == '1'" ref="video" id="videoPlayer" :src="fileList[curFileIndex].path"  class="video-js vjs-default-skin" style="background-color: black;" controls width="100%" height="100%" controlslist="nodownload"></video>
            <div v-if="fileType == 3 && fileList[curFileIndex].link" style="font-size: 14px;margin-bottom: 10px">
              实操跳转链接：{{fileList[curFileIndex].link}} <a :href="fileList[curFileIndex].link" target="_blank" style="color:#2d8cf0;text-decoration: underline;">点击跳转</a>
            </div>
            <embed  v-if="fileType != '1' && fileList[curFileIndex].path" id="iframe" ref="myIframe" :src="fileList[curFileIndex].path" width="100%" height="100%" type="application/pdf" />
          </div>
        </div>
    </el-drawer>
    <el-drawer
        :visible.sync="checkExperimentModal"
        size="80%">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ drawerTitle }}</div>
      </div>
      <div class="experiment">
        <div class="experiment-pdf" >
          <div class="experiment-pdf-top">
            <div class="name-list">
              <div :class="curFileIndex == index ? 'active' : ''" v-for="(item,index) in fileList" :key="item.id" @click="changeFileName(index,item)">{{ item.name }}</div>
            </div>
            <div>
              <el-button v-for="item in experInfoList" :key="item.id" style="margin-left: 10px;" :loading="experLinkLoading && item.id===loadingId" type="primary" size="small" @click="openExperiment(item,true)">{{ item.name }}</el-button>
              <el-button v-for="item in linkInfoList" :key="item.id" style="margin-left: 10px;" type="primary" size="small" @click="openExperiment(item)">{{ item.name }}</el-button>
            </div>
          </div>

          <div v-if="fileList.length && fileList[curFileIndex].path" style="height: calc(100% - 76px);">
            <!-- <div v-if="fileType == 3 && fileList[curFileIndex].link" style="font-size: 14px;margin-bottom: 10px">
              实操跳转链接：{{fileList[curFileIndex].link}} <a :href="fileList[curFileIndex].link" target="_blank" style="color:#2d8cf0;text-decoration: underline;">点击跳转</a>
            </div> -->
            <embed  v-if="fileType != '1' && fileList[curFileIndex].path" id="iframe" ref="myIframe" :src="fileList[curFileIndex].path" width="100%" height="100%" type="application/pdf" />
          </div>
        </div>
      </div>

    </el-drawer>
    <el-drawer
        :visible.sync="checkDataModal"
        size="80%">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">查看试题</div>
      </div>
      <div style="padding: 10px 20px; height: calc(100% - 60px);">
        <div class="table">
          <Table border :columns="columns" :loading="loading" :data="dataList"></Table>
        </div>
        <div class="page">
          <MyPage :total="total" :current="page" :pageSize="pageSize" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
        </div>

      </div>
    </el-drawer>
    <el-dialog
        :visible.sync="dataModal"
        width="70%">
      <div class="dialog-title" slot="title">
        <div class="dialog-title-text">试题详情</div>
      </div>
      <div>
        <DataInfo v-if="dataModal" :dataId="curData.data_id"></DataInfo>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DataInfo from '../../../course/components/dataInfo';
import util from '@/utils/tools.js';
//引入全屏插件
import screenfull from "screenfull";
export default {
  name: "directory",
  data(){
    return {
      treeList:[],
      exerciseId:'',
      checkFileModal:false,
      videoPath:'',
      pptPath:'',
      fileType:'',
      fileList:'',
      curFileIndex:0,
      courseId:'',
      drawerTitle:"",
      checkDataModal:false,
      dataModal:false,
      columns:[
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '所属章节',
          render:(h,params)=>{
            return h('span', params.row.node ? params.row.node.name : '');
          }
        },
        {
          title: '试题名称',
          render:(h,params)=>{
            return h('span', params.row.simpleData ? params.row.simpleData.title : '');
          }
        },
        {
          title: '更新时间',
          render:(h,params)=>{
            return h('span', util.timeFormatter(new Date(+params.row.simpleData.updated_at*1000), 'yyyy-MM-dd hh:mm'));
          }
        },
        {
          title: '操作',
          render:(h,params)=>{
            return h('div', [
              h('Button', {
                props: {
                  type: 'text',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.dataModal = true;
                  }
                }
              }, '预览'),
            ]);
          }
        },
      ],
      dataList:[],
      loading:false,
      total:0,
      page:1,
      pageSize:10,
      mapId:'',
      curData:{},
      curNodeId:'',
      checkExperimentModal:false,
      linkInfoList:[],
      experInfoList:[],
      experLinkLoading:false,
      loadingId:"",
      mdNode:{},
      isStudent:null,
      nodeId:""
    }
  },
  components:{
    DataInfo
  },
  props:{
    tree:{
      type:Array,
      default:()=>{
        return []
      }
    },
    isBought:{
      type:String,
      default:''
    },
    studyStatus:{
      type:String,
      default:''
    },
    categoryId:{
      type:String,
      default:''
    }
  },
  watch:{
    tree:{
      handler(newV){
        console.log(newV,'newVnewV======')
        this.treeList = JSON.parse(JSON.stringify(newV));
        this.treeList.forEach((item,index)=>{
          if(index == 0){
            this.$set(item,'show',true)
          }else{
            this.$set(item,'show',false)
          }
          this.recursion(item.children);
        })
        this.mapId=newV[0].map_id;
      },
      immediate:true,
    }
  },
  created(){
    this.exerciseId = this.$route.query.exerciseId || '';
    this.courseId = this.$route.query.courseId || '';
    this.isStudent = this.$route.path == '/trainingcamp/chapters';
  },
  methods:{
    recursion(data){
      data.forEach((item,index)=>{
        this.$set(item,'index',index);
        if(item.children && item.children.length>0){
          this.recursion(item.children);
        }
      })
    },
    toggle(data){
      data.show = !data.show;
    },
    goTrain(data){
      this.mdNode=data;
      this.dataBurialPoint("data",data);
      if(this.categoryId){  //在章节列表中试题是去练习
        if(this.courseId || this.exerciseId){
          this.$router.push({
            path:'/course/trainingcamp/trainDetail',
            query:{
              // id:this.categoryId,
              dataSort:'1',
              data_type:10,
              generate_id:data.node_child_id,
              from_id:this.courseId || this.exerciseId,
              from_type:'5',
              type:0,
            }
          })
        }else{
          this.$router.push({
            path:'/trainingcamp/trainDetail',
            query:{
              id:this.categoryId,
              dataSort:'10',
              data_type:10,
              generate_id:data.node_child_id
            }
          })
        }
      }else{  //在课程详情中试题是查看
        this.checkDataModal = true;
        this.curNodeId = data.node_child_id;
        this.page = 1;
        this.pageSize = 10;
        this.getList();
      }

    },
    checkPdf(data){
      if(this.exerciseId){
        this.taskEventLog(data.node_child_id,'ppt');
      }
      this.checkFile(data.ppt_info,'2',data)

      this.dataBurialPoint("ppt");
    },
    checkVideo(data){
      if(this.exerciseId){
        this.taskEventLog(data.node_child_id,'vedio');
      }
      this.checkFile(data.vedio_info,'1',data)
      this.dataBurialPoint("video");
    },
    checkTest(data){
      if(this.exerciseId){
        this.taskEventLog(data.node_child_id,'enchiridion');
      }
      this.checkFile(data.enchiridion_info,'3',data)
      this.dataBurialPoint("enchiridion");
    },
    taskEventLog(id,event){
      let params = {
        exercise_id:this.exerciseId,
        event:event,
        node_id:id
      };
      this.api.course.mTaskEventLog(params).then((res)=>{

      })
    },
    checkFile(data,type,nodeData){
      this.mdNode=nodeData;
      if(type == 3){
        this.experInfoList=nodeData.experiment_info||[];
        this.linkInfoList=nodeData.link_info||[];
        this.checkExperimentModal = true;
      }else{
        this.checkFileModal = true;
      }

      this.fileType = type;
      this.fileList = [];
      this.curFileIndex = 0;
      data.forEach((item)=>{
        if(!item.path.indexOf('http')  == 0){ //不以http开头
          item.path = this.constant.URL + '/uploadfile/show/?file=' + item.path;
        }
        this.fileList.push({
          ...item
        })
      });
      if(type==="1"){
        this.drawerTitle=`查看视频-${nodeData.node.name}`;
      }else if(type==="2"){
        this.drawerTitle=`查看课件-${nodeData.node.name}`;
      }else if(type==="3"){
        this.drawerTitle=`查看实验-${nodeData.node.name}`;
      }
    },
    dataBurialPoint(type,index){
      if(!this.isStudent) return;

      const { courseId,mapId,mdNode }=this;
      if(!courseId) return;
      let sourceId="";
      if(type==="ppt"){
        sourceId=mdNode.ppt_info[0].id;
        if(index>0){
          sourceId=mdNode.ppt_info[index].id;
        }
      }else if(type==="video"){
        sourceId=mdNode.vedio_info[0].id;
      }else if(type==="enchiridion"){
        sourceId=mdNode.enchiridion_info[0].id;
      }else if(type==="data"){
        sourceId=mdNode.node_child_id;
      }
      this.api.dataset.dataBurialPoint({
        course_id:courseId,
        source_type:type,
        map_id:mapId,
        node_id:mdNode.node_child_id,
        source_id:sourceId
      }).then(res=>{
        console.log(res,"数据埋点");
      })
    },
    changeFileName(index,item){
      console.warn(item);
      // this.nodeData=item;
      this.curFileIndex = index;
      this.dataBurialPoint("ppt",index);
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        map_id:this.mapId,
        node_id:this.curNodeId
      };
      this.loading = true;
      this.api.course.mapDataList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    openExperiment(item,authUrl){
      if(authUrl){
        if(item.type==="0"){
          this.$router.push({
            path:"/trainingcamp/experiment-code",
            query:{
              experiment_id:item.id
            }
          });
        }else{
          this.loadingId=item.id;
          this.experLinkLoading=true;
          this.api.course.getLinkAuthUrl({
            experiment_id:item.id
          }).then(res=>{
            this.experLinkLoading=false;
            window.open(res.link,"_blank");
          });
        }
      }else{
        window.open(item.path,"_blank");
      }
      // window.open(`http://hk.labeltool.edu360.cn/thirdpartylogin?id=${this.$store.state.user.userInfo.id}&name=${this.$store.state.user.userInfo.realname || this.$store.state.user.userInfo.nickname}`,'_blank');
    }
  }
}
</script>

<style scoped lang="scss">
.directory{
  margin-top: 20px;
  padding-bottom: 60px;
  .directory-item{
    //margin-bottom: 20px;
    font-size: 16px;
    .directory-item-tit{
      padding: 0 60px 0 30px;
      height: 60px;
      background: #F6F7FA;
      border-radius: 10px;
      //@include flex(row,space-between,center);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      .directory-item-tit-l{
        >span{
          margin-right: 18px;
        }
      }
      .directory-item-tit-r{
        background: url("../../../../assets/images/new_icon/x.png") no-repeat;
        background-size: 100% 100%;
        width: 17px;
        height: 10px;
        transform:rotate(0deg);
        transition:transform 0.3s;
      }
    }
    //.directory-item-tit:hover{
    //  background: #4E83FF;
    //  color: #FEFEFE;
    //
    //  .directory-item-tit-r{
    //    background: url("../../../../assets/images/new_icon/bx.png") no-repeat;
    //    background-size: 100% 100%;
    //  }
    //}
    .directory-item-list{
      //margin-left: 66px;
      margin: 10px 20px;
      max-height:0px;
      transition:max-height 0.5s;
      overflow: hidden;
      .directory-item-list-li{
        height: 70px;
        border-bottom: 1px solid #EEEEEE;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .directory-item-list-li-subtit{
          margin-top: 6px;
          color:#666666;
          font-size: 14px;
        }
        .directory-item-list-li-gray-btn{
          //@include btn(90px,30px,15px,14px,#E0E0E0,#999999);
          width: 90px;
          height: 30px;
          background: #E0E0E0;
          border-radius: 15px;
          font-size: 14px;
          color: #999999;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
        .directory-item-list-li-btns{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .directory-item-list-li-btn{
          margin-left: 10px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          padding: 3px 16px;
          border-radius: 30px;
          word-break: keep-all;
          font-size: 14px;
          color:#333333;
          font-weight: bold;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
          background-color: #EEEEEE;
          .icon{
            width: 16px;
            height:16px;
            display:block;
            margin-right: 3px;
          }
          >span{
            color: #666;
            font-weight: normal;
          }
        }
        .directory-item-list-li-btn:hover{
          background: #5578F6;
          color:#FFFFFF;
          .icon{
            filter:brightness(100);
          }
          >span{
            color:white;
          }
        }
      }
      .directory-item-list-li:hover{
        .directory-item-list-li-tit{
          color: #4E83FF;
        }
      }
      //.directory-item-list-li:hover{
      //  .directory-item-list-li-btns{
      //    display: flex;
      //    justify-content: flex-start;
      //    align-items: center;
      //  }
      //}
      //.directory-item-list-li:hover{
      //  color: #1664FF;
      //  .directory-item-list-li-btn{
      //    width: 90px;
      //    height: 30px;
      //    background: #1664FF;
      //    border-radius: 15px;
      //    font-size: 14px;
      //    color:#FFFFFF;
      //    text-align: center;
      //    line-height: 30px;
      //    cursor: pointer;
      //  }
      //}
    }
  }
}
.page{
  padding: 20px 0;
  text-align: right;
}
.name-list{
  // margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  >div{
    margin: 0 10px 10px 0;
    padding: 0 10px;
    border: 1px solid #dcdee2;
    height: 35px;
    line-height: 35px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }
  .active{
    border: 1px solid #2d8cf0;
    color: #2d8cf0;
  }
}
.experiment{
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: calc(100% - 20px);
  .experiment-pdf{
    margin-right: 20px;
    height: 100%;
    .experiment-pdf-top{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  >div{
    flex: 1;
    width: 0;

  }
  .experiment-r{
    .experiment-r-iframe{
      width: 100%;
      height: calc(100% - 32px);
    }
  }
}

</style>
<style>
.directory .el-tree-node__content{
  height: 70px !important;
}
.directory .el-tree-node__content:hover{
  background-color: #F5F7FA;
  border-radius: 10px;
}
.directory .el-tree-node__content>.el-tree-node__expand-icon{
  font-size: 18px;
}
.directory .el-tree-node:focus>.el-tree-node__content{
  border-radius: 10px;
}

</style>
