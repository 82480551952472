<template>
  <div class="data-cont"  >
    <div v-if="dataDeatil.id">
      <div class="tit">
        <Richtxt :dataObj="{val:dataDeatil.name}" :isInline="true" :disabled="true"></Richtxt>
      </div>
      <div class="options">
        <div v-for="(item, i) in dataDeatil.options" :key="item.id">
        <span class="options-item">
          <Richtxt :dataObj="{val:item.name}" :isInline="true" :disabled="true"></Richtxt>
        </span>
        </div>
      </div>
      <div class="correct" v-if="dataDeatil.type == 1 || dataDeatil.type == 2 || dataDeatil.type == 3">
        正确答案：<span>{{getRightAnswer()}}</span>
      </div>
      <div class="analysis" style="display: inline-block;">
        <p>解析:</p>
        <Richtxt :dataObj="{val:dataDeatil.explain}" :isInline="true" :disabled="true" v-if="dataDeatil.explain"></Richtxt>
      </div>
      <div class="mb10">题型 : {{ types[dataDeatil.type] }} </div>
      <div class="mb10">难度 : {{ levels[dataDeatil.level] }} </div>
      <div class="mb10">创建时间: {{ dataDeatil.created_at }} </div>
    </div>
    <div class="loading" v-if="loading">
      <Spin fix></Spin>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import Richtxt from '@/components/production/richtxt.vue';
export  default {
  name:'dataInfo',
  data(){
    return {
      dataDeatil:{},
      statuses:{},
      types:{},
      levels:{},
      loading:false,
    }
  },
  props:{
    dataId: {
      type: String,
      default: '',
    },
  },
  components:{
    Richtxt
  },
  created(){
    this.getDetail();
  },
  methods:{
    getDetail(){
      let params = {
        id:this.dataId
      };

      this.loading = true;
      this.api.dataset.dataDetail(params).then((res)=>{
        this.loading = false;
        this.dataDeatil = res.info;

        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.dataDeatil.created_at = util.timeFormatter(
            new Date(+res.info.created_at * 1000),
            'yyyy-MM-dd'
        );
        this.dataDeatil.updated_at = util.timeFormatter(
            new Date(+res.info.updated_at * 1000),
            'yyyy-MM-dd'
        );
        this.statuses = res.statuses;
        this.types = res.types;
        this.levels = res.levels;

      }).catch((err)=>{
        this.loading = false;
      })
    },
    getRightAnswer(){
      let rightanswer = '';
      if(this.dataDeatil.options){
        this.dataDeatil.options.forEach((item, i)=>{
          if (item.is_correct == '1'){
            var codestr = (i + 65);
            rightanswer +=  String.fromCharCode(codestr); //str.charCodeAt()
          }
        });
      }
      return rightanswer;
    }
  }
}
</script>

<style scoped lang="scss">
.data-cont{
  //margin-top: 20px;
  font-size: 14px;

  .tit{
    font-size: 16px;
    display: inline-block;
  }
  .options{
    margin-top: 10px;
    margin-left: 10px;
    >div{
      line-height: 40px;
    }
    .options-item{
      display: inline-block;
      width: 100%;
    }
  }
  .correct{
    margin-top: 10px;
    margin-bottom: 10px;
    color:#409EFF;
  }
  .analysis{
    margin-bottom: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
  }
}
.loading{
  width: 100%;
  height: 400px;
  position: relative;
}
</style>
